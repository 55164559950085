<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-02 15:33:04
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-03-29 10:41:08
-->
<template>
    <div class="accountList">
       <commonSelect
           @changeDate="changeDate"
           @clickTagDate="clickTagDate"
       ></commonSelect>
        <div class="table-wrap">
            <div class="title-wrap">
                <div class="tw-item">
                    <div class="t-item">
                        <el-select filterable clearable v-model="ep_id" 
                            @change="selectEpChange" placeholder="请选择品牌筛选">
                            <el-option
                                v-for="item in epOptions"
                                :key="item.ep_id"
                                :label="item.name"
                                :value="item.ep_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="t-item" v-if="ep_id != ''">
                        <el-select filterable clearable v-model="shop_id" 
                            @change="selectShopChange" placeholder="请选择门店筛选">
                            <el-option
                                v-for="item in shopOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="t-item" style="width: 150px">
                        <el-select clearable v-model="userId" @change="selectChange" placeholder="请选择客服筛选">
                            <el-option
                                v-for="item in userOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="t-item" style="width: 150px">
                        <el-select filterable clearable v-model="duties_id"
                            @change="selectDutiesChange" placeholder="请选择职务筛选">
                            <el-option
                                v-for="item in dutiesOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="t-item" style="width: 150px">
                        <el-select filterable clearable v-model="viscosity_status"
                            @change="selectViscosityChange" placeholder="请选择本月黏度筛选">
                            <el-option
                                v-for="item in viscosityOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-wrap">
                        <el-input v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <div class="tag-wrap">
                    <div class="tw-item" style="margin-left: 0px">门店状态：</div>
                    <div class="tw-item">
                        <div class="item" style="border: 1px solid #ddd;"></div>正常
                    </div>
                    <div class="tw-item">
                        <div class="item" style="background: #e6a23c"></div>即将过期
                    </div>
                    <div class="tw-item">
                        <div class="item" style="background: #f56c6c"></div>已过期
                    </div>
                    <div class="tw-item">
                        <div class="item" style="background: #dddddd"></div>被停用
                    </div>
                </div>
            </div>
            <el-table :data="tableData" height="580" border style="width: 100%" v-loading="loading" :row-class-name="tableRowClassName">
                <el-table-column align="center" prop="id" label="ID" width="70"></el-table-column>
                <el-table-column align="center" prop="ep_name" label="品牌名称" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" class-name="vm-wrap" prop="shop_name" label="云店名称" width="130" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div class="tag" v-if="scope.row.shop_is_new == 1">
                            <div class="new">NEW</div>
                        </div>
                        <div>{{scope.row.shop_name}}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="username" label="用户名称" width="100" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="duties_name" label="角色" width="100"></el-table-column>
                <el-table-column align="center" prop="mobile" label="联系电话" width="120"></el-table-column>
                <el-table-column align="center" prop="last_month_viscosity" width="120">
                    <template #header>
                            <div class="sort-wrap">
                                <span>上月黏度状态</span>
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content">
                                    高粘度：上月使用天数大于10<br/>
                                    低粘度：上月使用天数小于10天<br/>
                                    未使用：上月未使用<br/>
                                </div>
                                    <i class="iconfont el-icon-warning"></i>
                                </el-tooltip>
                            </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="viscosity" width="120">
                    <template #header>
                            <div class="sort-wrap">
                                <span>本月黏度状态</span>
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content">
                                    高粘度：本月使用天数大于10<br/>
                                    低粘度：本月使用天数小于10天<br/>
                                    未使用：本月未使用<br/>
                                </div>
                                    <i class="iconfont el-icon-warning"></i>
                                </el-tooltip>
                            </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="daily_active_time" label="最后登录时间" width="120" :show-overflow-tooltip="true"></el-table-column>
<!--                <el-table-column align="center" prop="log_shop_function_count" width="100">-->
<!--                    <template #header>-->
<!--                        <el-tooltip class="item" effect="dark" content="根据使用功能排序" placement="top">-->
<!--                            <div class="sort-wrap" @click="changeShow('log_shop_function_count')">-->
<!--                                <span>使用功能</span>-->
<!--                                <i class="iconfont el-icon-caret-bottom"></i>-->
<!--                            </div>-->
<!--                        </el-tooltip>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column align="center" prop="use_log_shop_function" label="本月使用功能" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="log_shop_function_count" label="本月使用功次数" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="customer_count" width="90">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据客户排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('customer_count')">
                                <span>客户</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="customer_record_count" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据成交单量排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('customer_record_count')">
                                <span>成交单量</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="business_gmv_sum" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据成交金额排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('business_gmv_sum')">
                                <span>成交金额</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="browsing_records_count" width="90">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据曝光排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('browsing_records_count')">
                                <span>曝光</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="comment_count" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据口碑积攒排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('comment_count')">
                                <span>口碑积攒</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="vr_imgs_count" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="跟据全景设计排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('vr_imgs_count')">
                                <span>全景设计</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="quotation_count" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="跟据报价数排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('quotation_count')">
                                <span>报价数</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="quotation_share_count" width="120">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="跟据报价分享数排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('quotation_share_count')">
                                <span>报价分享数</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="create_timex" label="创建时间" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="customer_server_name" label="客服负责人" width="100"></el-table-column>
                <el-table-column align="center" prop="allocate_time" label="分配时间" width="120"  :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <div class="operation-wrap">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <i @click="clickInfo(scope.row)" class="iconfont el-icon-document"></i>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="门店详情" placement="top">
                                <i @click="clickShopInfo(scope.row)" class="iconfont el-icon-s-shop"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="50"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import commonSelect from "@/components/commonSelect.vue"
export default {
    components: {
        commonSelect
    },
    data () {
        return {
            currentPage: 1,
            keyword: '',
            system_admin_id: '',
            ep_id: '',
            duties_id: '',
            shop_id: '',
            userId: '',
            tableData: [],
            total: 0,
            loading: false,
            epOptions: [],
            userOptions: [],
            dutiesOptions: [],
            shopOptions: [],
            viscosityOptions: [{
                    name: '未激活',
                    id: -1
                },{
                    name: '未使用',
                    id: 1
                },{
                    name: '低粘度',
                    id: 2
                },{
                    name: '高粘度',
                    id: 3
            }],
            viscosity_status: '',
            sortBy: '',
            dateType: '',
            startDate: '',
            endDate: '',
        }
    },
    mounted () {
        this.getAccountList();
        this.getEpConditions();
        this.getDutiesConditions();
        this.getUserConditions();
    },
    methods: {
        // 点击排序
        changeShow(item){
            this.currentPage = 1;
            this.sortBy = item;
            this.getAccountList();
        },
        // 跳转门店详情
        clickShopInfo(row){
            localStorage.removeItem('shop')
            let newPage = this.$router.resolve({ 
                path: 'shopDataInfo', 
            });
            window.open(newPage.href, '_blank');
            localStorage.setItem('shop',JSON.stringify(row));
            localStorage.setItem('jump',4);
        },
        //跳转详情
        clickInfo(row){
            localStorage.removeItem('account')
            let newPage = this.$router.resolve({ 
                path: 'accountDataInfo', 
            });
            window.open(newPage.href, '_blank');
            localStorage.setItem('account',JSON.stringify(row));
        },
        selectDutiesChange(){
            this.currentPage = 1;
            this.getAccountList();
        },
        selectViscosityChange(){
            this.currentPage = 1;
            this.getAccountList();
        },
        selectEpChange(){
            this.currentPage = 1;
            this.getAccountList();
            this.getShopConditions();
        },
        selectShopChange(){
            this.currentPage = 1;
            this.getAccountList();
        },
        selectChange(){
            this.currentPage = 1;
            this.getAccountList();
        },
        clickSearch(){
            this.currentPage = 1;
            this.getAccountList();
        },
        // 获取列表数据
        getAccountList(){
            let params = {
                ep_id: this.ep_id,
                shop_id: this.shop_id,
                system_admin_id: this.userId,
                duties_id: this.duties_id,
                keyword: this.keyword,
                page: this.currentPage,
                sort_by: this.sortBy,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                viscosity_status: this.viscosity_status
            }
            this.loading = true;
            common.connect('/customerservicev1/user/index',params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            })
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.currentPage = 1;
            this.startDate = startDate.slice(0,11)+' 00:00:01';
            this.endDate = endDate.slice(0,11)+' 23:59:59';
            this.getAccountList()
        },
        clickTagDate(item){
            this.dateType = item.label;
            this.currentPage = 1;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getAccountList();
            }
        },
        // 获取用户列表
        getUserConditions(){
            common.connect("/customerservicev1/admin/userConditions",{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.user_id,
                    label: v.nickname
                }
                })
                let quan = {
                    label: '暂无分配客服',
                    value: -1
                }
                resultSecond.unshift(quan);
                this.userOptions = resultSecond;
            });
        },
        // 获取品牌下拉数据
        getEpConditions(){
            common.connect('/customerservicev1/enterprise_brand/EpConditions',{},(res)=>{
                this.epOptions = res.data
            })
        },
        // 获取门店下拉数据
        getShopConditions(){
            common.connect('/customerservicev1/shop/allShopConditions',{ep_id: this.ep_id},(res)=>{
                this.shopOptions = res.data
            })
        },
        // 获取负责人数据
        getDutiesConditions(){
            common.connect("/customerservicev1/user/dutiesConditions",{},(res)=>{
                this.dutiesOptions = res.data;
            });
        },
        // 判断
        tableRowClassName({row, rowIndex}){
             if(row.shop_status === 2){
                return 'warning-row';
            }else if(row.shop_status === 3){
                return 'danger-row';
            }else if(row.shop_status === 4){
                return 'info-row';
            }
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getAccountList();
        },
    },
}
</script>

<style lang='scss'>
.accountList{
    padding: 0;
    .table-wrap{
        .title-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 10px;
            .tw-item{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .t-item{
                    padding-bottom: 10px;
                    margin-right: 10px;
                }
            }
            .select-wrap{
                margin-right: 20px;
            }
            .search-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-bottom: 10px;
                .el-input{
                    width: 200px;
                    margin-right: 10px;
                }
            }
        }
        .tag-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 5px;
            .tw-item{
                font-size: 14px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: 10px;
                .item{
                    margin-right: 5px;
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    overflow: hidden;
                    background: #fff;
                }
            }
        }
        .pic{
            height: 25px;
            max-width: 60px;
            vertical-align: bottom;
        }
        .vm-wrap{
            position: relative;
            .tag{
                position: absolute;
                top: 0;
                left: 0;
                background: #72a5fe;
                color: #fff;
                font-size: 6px !important;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: first baseline;
                -webkit-clip-path: polygon(40px 0px, 0px 0px, 0px 40px, 0px 40px);
                .new{
                    transform: rotate(-45deg);
                    margin-left: 2px;
                    margin-top: 2px;
                    font-size: 6px !important;
                }
            }
        }
        .operation-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            .iconfont{
                font-size: 16px;
                padding: 0 8px;
                cursor: pointer;
            }
        }
    }
    .sort-wrap{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sort-item{
        background: #eee;
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 5px;
    }
    .el-table tbody tr:hover>td {
        background-color: transparent !important;
    }
    .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf  {
        background-color: transparent !important;
    }
    .el-table__fixed-right tbody tr:hover>td {
        background-color: transparent !important;
    }
}
</style>
